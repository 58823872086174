import React from "react";
import './Faixa.css'

export default function Container2(){
    return(
        <div className="principal-container2">
            <div className="centralizar-container2">
                <div className="item-grid1-container2">
                    <span>Professores <br /> altamente <br /> qualificados</span>
                </div>
                <hr className="hr-grid1-container2"/>
                <div className="item-grid2-container2">
                    <span>17 anos <br /> de Dedicação </span>
                </div>
                <hr className="hr-grid2-container2"/>
                <div className="item-grid3-container2">
                    <span>Melhor <br /> Infraestrutura</span>
                </div>
                <hr className="hr-grid3-container2"/>
                <div className="item-grid4-container2">
                    <span>Preço Justo</span>
                </div>
            </div>
        </div>
    )
}