import React from "react";
import './ModalCards.css'

import x from '../../image/Vector.svg'

import Modal from "react-modal";

export default function ModalCards(props){

    const closeModal = () =>{
        document.body.style.overflowY = 'unset';
        props.chamandoCbModalClose(false)
    }

    return(
        <>
            <Modal
                isOpen={props.modalOpen1}
                onRequestClose={closeModal}
                className="principal-modalCards"
            >
                <div>
                    <div>
                        <img src={props.imagem} alt="" />
                    </div>
                    <div  className="button-x-modalcard">
                        <button onClick={closeModal}> <img src={x} alt="" /> </button>
                        <h1>{props.titulo}</h1>
                        <p>{props.texto1}</p>
                        <p>{props.texto2}</p>
                        <ul>
                            <li>{props.texto3}</li>
                            <li>{props.texto4}</li>
                            <li>{props.texto5}</li>
                            <li>{props.texto6}</li>
                        </ul>
                    </div>
                </div>
            </Modal>
        </>
    )
}