import React from "react";
import './CarrosselMetodologia.css'
import json from '../../json/metodologia.json'

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation, EffectCoverflow } from 'swiper';

export default function CarrosselMetodologi() {
    return (
        <div className="principal-carrossel-metodologia">
            <h1>Metodologia do <span>Baluarte</span></h1>

            <p>
                O Colégio Baluarte adota uma metodologia inovadora, focada no pensamento crítico, criatividade e desenvolvimento integral dos alunos. Destaca-se pela Aprendizagem Ativa, Integração de Tecnologia, Projeto Bilíngue e cuidado Socioemocional, promovendo aprendizado além da sala de aula e envolvimento familiar para formar cidadãos preparados para o futuro.
            </p>

            <Swiper
                navigation={true}
                slidesPerView='auto'
                modules={[Pagination, Navigation, EffectCoverflow]}
                className="mySwiper"
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                coverflowEffect={{
                    rotate: 0,
                    stretch: -50,
                    depth: 150,
                    modifier: 2,
                    slideShadows: false,
                }}
                spaceBetween={40}
                pagination={{
                    clickable: true,
                }}
                speed={700}
                initialSlide={1}
            >
                {json.map((item) => {
                    return (
                        <SwiperSlide key={item.id}>
                            <div className="div-carrossel-metodologia">
                                <div className="alinha-carrossel-metodologia">
                                    <h1>{item.titulo}</h1>
                                    <p>{item.conteudo}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>

        </div>
    )
}