import React from "react";
import './CarrosselMetodologiaResponsive.css'
import json from '../../json/metodologia.json'

export default function carrosselMetodologiaResponsive() {
    return (
        <div className="principal-carrossel-metodologia-responsive">
            <h1>Metodologia do <span>Baluarte</span></h1>

            <p>
                O Colégio Baluarte adota uma metodologia inovadora, focada no pensamento crítico, criatividade e desenvolvimento integral dos alunos. Destaca-se pela Aprendizagem Ativa, Integração de Tecnologia, Projeto Bilíngue e cuidado Socioemocional, promovendo aprendizado além da sala de aula e envolvimento familiar para formar cidadãos preparados para o futuro.
            </p>
            {json.map((item) => {
                return (
                    <div key={item.id}>
                        <div className="div-carrossel-metodologia-responsive">
                            <div className="alinha-carrossel-metodologia-responsive">
                                <h1>{item.titulo}</h1>
                                <p>{item.conteudo}</p>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}