import React, { useEffect, useState } from "react";
import './CarrosselEstrutura.css'
import json from '../../json/estruturas.json'

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation, EffectCoverflow } from 'swiper';

export default function CarrosselEstrutura() {


    return (
        <div className="principal-carrossel-estrutura">

            <h1>Nossa <span>estrutura</span></h1>

            <p>
                O Colégio Baluarte oferece infraestrutura adequada para todas as idades, com salas dedicadas a cada fase do desenvolvimento e um playground seguro para os pequenos. Valoriza esportes e atividades criativas, em quadra coberta e salas equipadas com TVs para aprendizado dinâmico.
            </p>

            <Swiper
                loop={true}
                navigation={true}
                modules={[Pagination, Navigation, EffectCoverflow]}
                breakpoints={{
                    300: {
                        width: 250,
                        slidesPerView: 1,
                    },
                    500: {
                        width: 650,
                        slidesPerView: 3,
                    },
                }}
                spaceBetween={21}
                pagination={{
                    clickable: true,
                }}
                className="mySwiper"
                grabCursor={true}
                speed={700}
            >

                {json.map((item) => {
                    return (
                        <SwiperSlide key={item.id}>
                            <div className="div-carrossel-estrutura">
                                <div className="alinha-carrossel-estrutura">
                                    <img src={item.img} alt="" />
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}