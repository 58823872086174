import React, { useState, useRef } from 'react';
import './App.css';

import Header from './components/header/Header';
import Apresentacao from './components/apresentacao/Apresentacao';
import Faixa from './components/faixa/Faixa';
import FaixaResponsive from './components/faixaResponsive/FaixaResponsive';
import Cards from './components/cards/Cards';
import CarrosselEstrutura from './components/carrosselEstrutura/CarrosselEstrutura';
import CarrosselMetodologi from './components/carrosselMetodologia/CarrosselMetodologia';
import CarrosselMetodologiaResponsive from './components/carrosselMetodologiaResponsive/CarrosselMetodologiaResponsive';
import Footer from './components/footer/Footer';
import FooterResponsive from './components/footerResponsive/FooterResponsive';
import CardsResponsive from './components/cardsResponsive/CardsResponsive';

function App() {


  const DiferenciaisScroll = useRef(null);
  const EstruturaScroll = useRef(null);
  const MetodologiaScroll = useRef(null);
  const FooterScroll = useRef(null)

  const handleScroll = r => {
    if (r === 1) {
      DiferenciaisScroll.current && DiferenciaisScroll.current.scrollIntoView({ behavior: "smooth" });
    } else if (r === 2) {
      EstruturaScroll.current && EstruturaScroll.current.scrollIntoView({ behavior: "smooth" });
    } else if (r == 3) {
      MetodologiaScroll.current && MetodologiaScroll.current.scrollIntoView({ behavior: "smooth" });
    } else if (r == 4) {
      FooterScroll.current && FooterScroll.current.scrollIntoView({ behavior: "smooth" });
    }
  }


  return (
    <div className='main'>
      <Header
        handleScrollchildren={handleScroll}
      />
      <Apresentacao
        handleScrollchildren={handleScroll}
      />
      <Faixa />
      <FaixaResponsive handleScrollchildren={handleScroll} />
      <span
        ref={DiferenciaisScroll}
        style={{ "position": "relative", "top": "100px" }}
      ></span>
      <Cards />
      <CardsResponsive/>
      <span
        ref={EstruturaScroll}
        style={{ "position": "relative", "top": "100px" }}
      ></span>
      <CarrosselEstrutura />
      <span
        ref={MetodologiaScroll}
        style={{ "position": "relative", "top": "170px" }}
      ></span>
      <CarrosselMetodologi />
      <CarrosselMetodologiaResponsive />
      <span
        ref={FooterScroll}
        style={{ "position": "relative", "top": "100px" }}
      ></span>
      <Footer />
      <FooterResponsive />
    </div>
  );
}

export default App;
