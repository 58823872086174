import React from "react";
import './FaixaResponsive.css'

export default function Container3(props) {

    const FaleConosco = () => {
        props.handleScrollchildren(4)
    }

    return (
        <div className="principal-container3">
            <p>
                O Colégio Baluarte oferece uma educação de excelência em dois idiomas, preparando os alunos para um futuro globalizado.
            </p>
            <div>
                <div className="alinha-botoes-container3">
                    <div className="circulo-play-container3">
                        <div className="triangulo-play-container3">
                        </div>
                    </div>
                    <a href="">Veja nosso colégio!</a>
                    <button onClick={FaleConosco}>Fale Conosco</button>
                </div>
            </div>
        </div>
    )
}