import React from "react";
import './Header.css'

import Logo from '../../image/Untitled.png'

function Header(props) {

    const handleScrollDiferenciais = () => {
        props.handleScrollchildren(1)
    }
    const handleScrollEstrutura = () => {
        props.handleScrollchildren(2)
    }
    const handleScrollMetodologia = () => {
        props.handleScrollchildren(3)
    }
    const FaleConosco = () => {
        props.handleScrollchildren(4)
    }
    return (
        <div className="principal-header">
            <div className="centraliza-header">
                <img src={Logo} alt="Logo do Colegio Baluarte" />

                <nav>
                    <a
                        onClick={handleScrollDiferenciais}
                    >Diferenciaiss</a>
                    <a
                        className="margin-link-header"
                        onClick={handleScrollEstrutura}
                    >Estrutura</a>
                    <a
                        onClick={handleScrollMetodologia}
                    >Metodologia</a>
                </nav>

                <div>
                    <button onClick={FaleConosco}>Fale Conosco</button>
                </div>
            </div>
        </div>
    )
}

export default Header