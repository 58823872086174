import React, { useRef } from 'react';
import './Footer.css'

import {
    AiOutlineWhatsApp, AiOutlineInstagram, AiOutlineYoutube
} from 'react-icons/ai';
import { RiFacebookLine } from 'react-icons/ri';
import { IoLocationSharp } from 'react-icons/io5';
import { BsFillTelephoneFill } from 'react-icons/bs';

import emailjs from '@emailjs/browser';

export default function Footer() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('gmailTeste', 'template_qh1ynlo', form.current, 'user_5KB1lIk7SBnLV5Uck6KFR')
            .then((result) => {
                alert("Menssagem enviada com sucesso!");
            }, (error) => {
                alert("Erro ao enviar menssagem");
            });

    };

    return (
        <div className="principal-footer">
            <div>
                <div className="alinha-endereco-footer">
                    <h1>
                        Entre em contato com a gente!
                    </h1>
                    <p>
                        Preencha o formulário ao lado e nossa equipe entrará em contato com você em até 24 horas.
                    </p>
                    <span><IoLocationSharp /> (11) 2498-1328 |  (11) 947802307</span>
                    <span><BsFillTelephoneFill /> R. Téles Píres, 240 - Parque Jurema, Guarulhos - SP</span>
                    <div>
                        <a href="https://api.whatsapp.com/send?phone=5511947802307" target="_blank" className="whats-footer"><AiOutlineWhatsApp /></a>
                        <a href="https://www.instagram.com/baluartecolegio/" target="_blank" className="insta-footer"><AiOutlineInstagram /></a>
                        <a href="https://www.facebook.com/colegiobaluarte.baluarte?mibextid=ZbWKwL" target="_blank" className="face-footer"><RiFacebookLine /></a>
                        <a href="https://www.youtube.com/channel/UCLPVrTcVuC29J2MRx0Ux3HA" target="_blank" className="youtube-footer"><AiOutlineYoutube /></a>
                    </div>
                </div>
                <div className="alinha-form-footer">
                    <form ref={form} onSubmit={sendEmail}>
                        <span>Nome</span>
                        <input type="text" name="name" placeholder='Digite o seu nome (obrigatório)' required />
                        <span className='margin-top'>Email</span>
                        <input type="email" name="email" placeholder='Digite o seu email (obrigatório)' required />
                        <span className='margin-top'>Mesagem</span>
                        <textarea name="message" placeholder='Digite a sua mensagem (opcional)' resize />
                        <button type="submit" value="Send" >Enviar</button>
                    </form>
                </div>
            </div>
        </div>
    )
}