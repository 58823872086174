import React, { useState } from "react";
import './Cards.css'

import inteligente from '../../image/CriancaInteligente.svg'
import yoga from '../../image/CriancaYoga.svg'
import ballet from '../../image/CriancaBallet.svg'
import judo from '../../image/CriancaJudo.svg'
import musica from '../../image/CriancaMusica.svg'

import ProjetoBilingue from '../../image/porjetoBilingue1.svg'
import Meditacao from '../../image/meditcao1.svg'
import Musicalizacao from '../../image/musicalizacao1.svg'
import Judo from '../../image/judo.svg'
import Ballett from '../../image/ballett1.svg'

import ModalCards from "../modalCards/ModalCards";

export default function Cards() {

    const [modalOpen1, setModalOpen1] = useState(false);
    const [modalOpen2, setModalOpen2] = useState(false);
    const [modalOpen3, setModalOpen3] = useState(false);
    const [modalOpen4, setModalOpen4] = useState(false);
    const [modalOpen5, setModalOpen5] = useState(false);

    const modalClose = r => {
        localStorage.setItem("abc", 1)
        setModalOpen1(r)
        setModalOpen2(r)
        setModalOpen3(r)
        setModalOpen4(r)
        setModalOpen5(r)
    }

    const handleOpenModal1 = () => {
        setModalOpen1(true)
        stiledComponents()
    }
    const handleOpenModal2 = () => {
        setModalOpen2(true)
        stiledComponents()
    }
    const handleOpenModal3 = () => {
        setModalOpen3(true)
        stiledComponents()
    }
    const handleOpenModal4 = () => {
        setModalOpen4(true)
        stiledComponents()
    }
    const handleOpenModal5 = () => {
        setModalOpen5(true)
        stiledComponents()
    }

    function stiledComponents(){
        localStorage.setItem("abc", 0)
        document.body.style.overflow = 'hidden';
    }


    return (
        <div className="principal-container4">
            <h1>Por quê nosso colégio <span>é diferente?</span></h1>

            <p>
                O Colégio Baluarte adota uma metodologia inovadora, focada no pensamento crítico, criatividade e desenvolvimento integral dos alunos. Destaca-se pela Aprendizagem Ativa, Integração de Tecnologia, Projeto Bilíngue e cuidado Socioemocional, promovendo aprendizado além da sala de aula e envolvimento familiar para formar cidadãos preparados para o futuro.
            </p>

            <div className="cards-container4">
                <div>
                    <img src={inteligente} alt="" />
                    <div className="alinha-cards-container4">
                        <h2>Projeto Bilíngue</h2>
                        <p>
                            Quando o Ensino Bilíngue começa cedo, as vantagens perduram por toda a vida.
                        </p>
                        <span onClick={handleOpenModal1}>Saiba mais</span>
                    </div>
                </div>

                <div>
                    <img src={yoga} alt="" />
                    <div className="alinha-cards-container4">
                        <h2>Meditação e Yoga</h2>
                        <p>
                            São inúmeros os benefícios da Meditação e do Brinca Yoga para as crianças!
                        </p>
                        <span  onClick={handleOpenModal2}>Saiba mais</span>
                    </div>
                </div>

                <div>
                    <img src={musica} alt="" />
                    <div className="alinha-cards-container4">
                        <h2>Musicalização Infantil</h2>
                        <p>
                            A musicalização infantil pode despertar talentos e emocionar corações desde cedo.
                        </p>
                        <span  onClick={handleOpenModal3}>Saiba mais</span>
                    </div>
                </div>

                <div>
                    <img src={judo} alt="" />
                    <div className="alinha-cards-container4">
                        <h2>Judô</h2>
                        <p>
                            Não é apenas um esporte, é uma arte que ensina disciplina, respeito e autocontrole.
                        </p>
                        <span  onClick={handleOpenModal4} >Saiba mais</span>
                    </div>
                </div>

                <div>
                    <img src={ballet} alt="" />
                    <div className="alinha-cards-container4">
                        <h2>Ballet e Jazz</h2>
                        <p>
                            Além de desenvolver habilidades motoras e coordenação, proporciona uma expressão artística única.
                        </p>
                        <span  onClick={handleOpenModal5}>Saiba mais</span>
                    </div>
                </div>
            </div>

            <ModalCards
                modalOpen1={modalOpen1}
                chamandoCbModalClose={modalClose}
                imagem={ProjetoBilingue}
                titulo="Projeto Bilíngue"
                texto1="Quando o Ensino Bilíngue começa cedo, as vantagens perduram por toda a vida, atualmente, a importância da aprendizagem do inglês representa mais que fluência em um segundo idioma."
                texto2="Todo o aprendizado envolve a aquisição de competências interculturais, que são capazes de alterar a percepção e ampliar a visão de mundo do falante por meio do contato com mais pessoas, culturas e ambientes. Do ponto de vista cognitivo, o aprendizado de um segundo idioma pode fortalecer a capacidade intelectual do aluno e, também as habilidades de escuta e atenção que demandam tal formação."
            />

            <ModalCards
                modalOpen1={modalOpen2}
                chamandoCbModalClose={modalClose}
                imagem={Meditacao}
                titulo="Meditação e Brinca Yoga"
                texto2="O termo Yoga significa “unir” e “integrar”, que tem origem na palavra “yuj” do idioma sânscrito, surgiu na Índia há mais de 5 mil anos. Dessa forma, a Yoga estimula a conexão entre o corpo e a mente, por meio de movimentos inspirados na natureza e do controle da respiração.A prática da meditação pode aumentar a capacidade de atenção da criança, de percepção de si. Uma criança mais consciente de suas emoções e sentimentos tem maior possibilidade de identificar suas necessidades, potencialidades e limitações. A habilidade de saber relaxar resulta no equilíbrio do sistema nervoso. Nestas práticas, o sistema nervoso parassimpático é ativado, o metabolismo corporal se acalma, baixando a frequência cardíaca e respiratória, aumentando a capacidade de aprender e se concentrar. Assim, práticas como o Yoga, relaxamento e a meditação, também podem auxiliar no desenvolvimento da aprendizagem ao longo da vida."
            />

            <ModalCards
                modalOpen1={modalOpen3}
                chamandoCbModalClose={modalClose}
                imagem={Musicalizacao}
                titulo="Musicalização Infantil"
                texto1="O trabalho com a musicalização infantil na escola é indispensável, pois, além do desenvolvimento da sensibilidade à música contribui para a concentração, memória, coordenação motora, socialização, acuidade auditiva e disciplina."
                texto2="A iniciação musical deve ter como objetivo durante a idade Pré-escolar, estimular na criança a capacidade de percepção, sensibilidade, imaginação, criação bem como age como uma recreação educativa, socializando, disciplinando e desenvolvendo a sua atenção."
            />

            <ModalCards
                modalOpen1={modalOpen4}
                chamandoCbModalClose={modalClose}
                imagem={Judo}
                titulo="Judô"
                texto1="Criado em 1882 no Japão, o judô, palavra japonesa que significa caminho suave ou caminho para a suavidade, é uma arte marcial que usa o corpo como uma alavanca para envolver e imobilizar o adversário, utilizando a força do outro em benefício próprio. Mas além de ser uma luta, é um esporte que traz inúmeros benefícios para quem o pratica."
                texto2="Nesse texto listamos alguns benefícios do judô:"
                texto3="-Judô emagrece e ajuda no ganho de força e condicionamento"
                texto4="-Benefícios do judô vão desde paciência a autocontrole"
                texto5="-Estímulo ao respeito aos mestres e adversários"
                texto6="-Judô aumenta a capacidade de analisar a realidade"
            />

            <ModalCards
                modalOpen1={modalOpen5}
                chamandoCbModalClose={modalClose}
                imagem={Ballett}
                titulo="Ballet e Jazz"
                texto1="O ballet é uma das atividades mais recomendadas para crianças e pode ser praticada por ambos os sexos. O ballet combina atividades físicas com musicalidade, lateralidade, coordenação motora e um pouco de teatralização, esta é considerada uma atividade bastante completa para as crianças. Além disso, o ballet auxilia na concentração, postura e ritmo, que são importantes para muitas outras atividades."
                texto2="O ballet é uma das atividades mais recomendadas para crianças e pode ser praticada por ambos os sexos. O ballet combina atividades físicas com musicalidade, lateralidade, coordenação motora e um pouco de teatralização, esta é considerada uma atividade bastante completa para as crianças. Além disso, o ballet auxilia na concentração, postura e ritmo, que são importantes para muitas outras atividades."
            />
        </div>
    ) 
}