import React from "react";
import './Apresentacao.css'

import Crianca from '../../image/Crianca.svg'

export default function Container1 (props){

    const FaleConosco = () => {
        props.handleScrollchildren(4)
    }

    return(
        <div className="principal-container1"> 
            <div className="centraliza-container1">
                <div className="esquerda-container1">
                    <h1>Desperte seu potencial em dois idiomas!</h1>
                    <p>
                        O Colégio Baluarte oferece uma educação de excelência em dois idiomas, preparando os alunos para um futuro globalizado.
                    </p>
                    <div>
                        <button onClick={FaleConosco}>Fale Conosco</button>
                        <div className="circulo-play-container1">
                            <div className="triangulo-play-container1">
                            </div>
                        </div>
                        <a href="">Veja nosso colégio!</a>
                    </div>
                </div>
                <div className="direita-container1">
                        <img src={Crianca} alt="" />
                </div>
            </div>
        </div>
    )
}